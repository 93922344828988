import React, { useState, useRef, useEffect } from 'react'
import { Switch } from '@headlessui/react'
import emailjs from 'emailjs-com';

import Confirm from './Confirmation'
import Contact from '../../images/6_ContactMe/PNG/contact.png'
import bg from '../../images/contact.jpg'
import submit from '../../images/6_ContactMe/PNG/button.png'
import UseInput from '../../customHooks/useInput'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const inputs = ['newName', 'email', 'message']
  const UseInputComp = () => {
  for (let i=0;i<inputs.length;i++) {

    return UseInput(inputs[i])
  }
}
const InputComp = UseInputComp().comp
const submitForm =  UseInputComp().submit

  const [agreed, setAgreed] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [validateName, setValidateName] = useState(undefined)
  const [validateEmail, setValidateEmail] = useState(undefined)
  const [validateMessage, setValidateMessage] = useState(undefined)

  const form = useRef()

const handleChange = (e) => {
  switch(e.target.name) {
      case 'email':
           if (validateEmail !== undefined) {
               setValidateEmail(undefined)
           }
          setEmail(e.target.value)
          break;
      case 'name':
          if (validateName !== undefined) {
              setValidateName(undefined)
          }
          setName(e.target.value)
          break;
      case 'message':
          if (validateMessage !== undefined) {
              setValidateMessage(undefined)
          }
          setMessage(e.target.value)
          break;
    }
}
const validateError = (label, which) => {
  const errorMessage = () => (
      <div className="text-red-500 absolute">
      * {which} input is empty
      </div>
  )
  console.log(label)
 switch (label) {
     case name:
          setValidateName(errorMessage)
          break;
     case email:
         setValidateEmail(errorMessage)
         break;
      case message:
          setValidateMessage(errorMessage)
          break;
 }
}
const onSubmit = (e) => {
  switch ('') {
      case name:
          validateError(name, 'name');
          e.preventDefault()
          break;
      case email:
          validateError(email, 'email')
          e.preventDefault()
          break;
      case message:
          validateError(message, 'message');
          e.preventDefault()
          break;

      default:
         /* setURL() */
         setConfirm(true)
         emailjs.sendForm('service_arikqvn', 'template_ht51ufi', e.target, 'user_kC0T8kmC4F1GOkt3Q06Q4')
         e.preventDefault()
  }
}
const confirmMessage = ['Thanks for reaching out to Websites By Trevor!', 'We will respond ASAP']
const navigation = {
  main: [
    { name: 'Home', href: '/' },
    { name: 'Product', href: '/product' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'Partnerships', href: 'https://partners.websitesbytrevor.com/' },
    { name: 'Blog', href: '/blog' },
    { name: 'Contact', href: '/contact' }
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/AylineArtin/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/aylineartin/?hl=en',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/aylineartin',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },


  ],
}
  return (
    <div className=" overflow-hidden sm:px-6 bg-black" style={{gridColumn:"span 7", gridRowStart:"sixth", gridRowEnd:"seventh", zIndex:"22"}}>
      <div className="flex flex-row justify-center items-end space-x-0 w-8/12 mx-auto sm:space-x-5 md:space-x-10">
      <img src={bg} width="200px" height="100px" className="mx-auto border-2 object-contain p-0 m-0 bg-no-repeat  h-72 relative right-0  w-80" />
      <div className="pr-4 relative max-w-xl mx-auto mx-auto" >

        <div className="text-center pt-24">
          <h2 className="mx-auto text-white text-lg -mt-12">
          <img src={Contact} width="150px" height="100px" className="mx-auto -mt-16" loading="lazy" />
          </h2>

        </div>

        <div className="-mt-10 h-52">
        {confirm ? <Confirm prompt="false" message={confirmMessage} confirm={() =>setConfirm(false)} /> : null}
          <form ref={form} onSubmit={onSubmit} id="contact" className="sm:grid-cols-2 sm:gap-x-8">
            <div className="sm:col-span-2">

              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                {validateName}
                </label>

              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="given-name"
                  value={name}
                  onChange={handleChange}
                  key='name'
                  placeholder="name"
                  className="py-0 px-4 mx-auto mb-4 block text-gray-400 bg-black w-44 shadow-sm  border-0 border-b-2 border-grey-300"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                 {validateEmail}
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={handleChange}
                  key='name'
                  placeholder="email address"
                  className="py-0 px-4 mx-auto mb-4 block text-gray-400 bg-black w-44 shadow-sm border-0 border-b-2 border-grey-300 "
                />
              </div>
            </div>


            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">

              </label> {validateMessage}
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={3}
                  value={message}
                  onChange={handleChange}
                  key='name'
                  placeholder="message"
                  className="py-0 px-4 mx-auto text-gray-400 mb-4 block resize-none bg-black w-44 shadow-sm  border-0 border-b-2 border-grey-300"

                />

              </div>
            </div>

            <div className="mt-8 sm:col-span-2">
              <button
                type="submit"
                className=" mx-auto block -mt-2"
              >
                <img src={submit} width="200px" height="50px" />
              </button>
            </div>
          </form>
        </div>
      </div>
      </div>
    </div>
  )
}